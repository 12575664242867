<template>
  <Card class="allSearchCard">
    <template>
      <template slot="title">
        <div class="card-info">
          <h3 class="name">{{cardInfo.accountName || cardInfo.name}}</h3>
          <span
            class="tag"
            v-if="type === 'trend' && cardInfo.retentionName"
          >{{cardInfo.retentionName}}</span>
        </div>
        <div class="description">
          <h4 class="accountMobile">
            <a :href="`tel:${cardInfo.qbsAccountPhone}`">
            <Icon
              name="phone"
            /></a>{{cardInfo.qbsAccountPhone || cardInfo.phone}}
          </h4>
        </div>
      </template>
      <template>
        <div class="msg-text">
          <span>{{type === 'notInto' ? '生日：' : '动向时间：'}}</span>
          <span>{{type === 'notInto' ? cardInfo.birthday : cardInfo.activitytime}}</span>
        </div>
        <div
          class="msg-text"
          style="display:flex;justify-content: space-between;"
        >
          <div>
            <span>{{type === 'notInto' ? '客户来源：' : '渠道：'}}</span>
            <span>{{type === 'notInto' ? cardInfo.source : cardInfo.sourceName}}</span>
          </div>
          <!-- <div v-if="type === 'trend'"
               @click="goAccounDetail(cardInfo.qbsAccountId)"
               style="color: #DA3738;">客户详情<Icon class="arrow-icon"
                  name="arrow"></Icon>
          </div> -->
        </div>
      </template>
      <template slot="footer">
        <span></span>
        <div>
          <a :href="`tel:${cardInfo.qbsAccountPhone}`">
            <Icon
              name="phone-circle-o"
              size="30"
              color="#1899FF"
            />
          </a>
          <Button
            round
            type="info"
            style="margin-right: 10px;"
            @click="goAccounDetail(cardInfo.qbsAccountId || cardInfo.eosAccountId)"
            size="small"
          >详情</Button>
          <Button
            round
            type="info"
            v-if="type === 'trend'"
            size="small"
            @click="finish(cardInfo.eosTrendsId)"
          >完成任务</Button>
        </div>
      </template>
    </template>
  </Card>
</template>
<script>
import { Button, Icon, Toast } from 'vant'
import Card from '_c/card'
import { orgCode } from '@/api/chargequery'
import 'vant/lib/icon/style'
import 'vant/lib/row/style'
import 'vant/lib/col/style'
import '@/style/vant-patch.less'
import './index.less'
export default {
  name: 'CardItem',
  props: {
    cardInfo: {
      type: Object,
      require: true
    },
    type: {
      type: String,
      require: true
    }
  },
  data () {
    return {
    }
  },
  components: {
    Card,
    Button,
    Icon
  },
  methods: {
    finish (id) {
      this.$emit('finish', id)
    },
    goAccounDetail (accountId) {
      this.$router.push({
        path: '/customerInfo',
        query: {
          id: accountId
        }
      })
    },
    // 点击电话按钮
    handlephine (cardinfo) {
      const callphone = cardinfo.qbsAccountPhone
      this.orgCode(callphone)
    },
    // 拨打电话
    async orgCode (callphone) {
      const res = await orgCode({ orgCode: callphone }) //
      if (res.code === '000000') {
        Toast('Calling')
      } else {
        Toast(res.msg)
      }
    },
  }
}
</script>